import Slider from "react-slick";
import { observer } from "mobx-react";
import { Store } from '../../store/store';
import { useContext, useState, useEffect } from "react";
import { ButtonLinkArray } from '../../component/button_link_array';
import { ButtonLink } from '../../component/button_link';
import styles from './organization.module.css';
import IconSearch from '../../tractor_image/icon/search.png';
import { CardOrganization } from '../../component/card_organization';
import iconClose from '../../tractor_image/icon/close.png';
import ImageButtonRedRight from '../../tractor_image/arrow/arrow_red_right.png'
import ImageSlider from '../../tractor_image/test_image/card_organization_image.png'
import Modal from 'react-modal';

const PrevArrow = ({ className, style, onClick }) => {
    return <img src={ImageButtonRedRight} alt="" className={styles.prevArrow} onClick={onClick} />

}
const NextArrow = ({ className, style, onClick }) => {
    return <img src={ImageButtonRedRight} alt="" className={styles.nextArrow} onClick={onClick} />
}

export const Organization = observer(() => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const store = useContext(Store);

    const settings = {
        dots: false,
        infinite: true,
        slidesToScroll: 1,
        adaptiveHeight: true,
        centerMode: true,
        slidesToShow: 3,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        beforeChange: (oldIndex, newIndex) => {
            setCurrentSlide(newIndex)
        }
    };

    const [data, setData] = useState({});
    const [search, setSearch] = useState("");
    useEffect(() => {
        (async () => {
            const res = await fetch("https://museum.dvizh.net/api/organization?search=" + search)
            const res_data = await res.json()
            console.log(res_data);
            setData(res_data);
        })()
    }, [search])

    const [modalIsOpen, setIsOpen] = useState(false)
    const [currentData, setCurrentData] = useState({})

    return <div className={styles.container}>
        <div className={styles.container_content}>
            <div className={styles.line_title}>
                <h1 className={styles.title}>
                    сельскохозяйственные <br />
                    предприятия и организации
                </h1>
                <div className={styles.search_container}>
                    <input type="text" className={styles.search_input} placeholder="Найти предприятия" value={search} onInput={(e)=>setSearch(e.target.value)} />
                    <img src={IconSearch} alt='' className={styles.search_icon} />
                </div>
            </div>

            <p className={styles.pageText}>
                А ещё реплицированные с зарубежных источников, современные исследования, вне зависимости от их уровня, должны быть указаны как претенденты на роль ключевых факторов. Банальные, но неопровержимые выводы, а также диаграммы связей формируют глобальную экономическую сеть и при этом — своевременно верифицированы. Каждый из нас понимает очевидную вещь: понимание сути ресурсосберегающих технологий предопределяет высокую востребованность форм воздействия.
            </p>
            <div className={styles.list}>
                {
                    data?.items?.map((item, index) => {
                        return <CardOrganization key={index} item={item} buttonClick={() => {setIsOpen(true); setCurrentData(item)}} />
                    })
                }

            </div>
            <div className={styles.bottom_links}>
                <ButtonLink to={'/about'}>
                    Назад
                </ButtonLink>
            </div>
        </div>

        <Modal
            isOpen={modalIsOpen}
            // onAfterOpen={afterOpenModal}
            onRequestClose={() => setIsOpen(false)}
            className={styles.modal}
            contentLabel="Example Modal"
            overlayClassName={styles.modal_bg}
        >
            <div className={styles.modal_head}>
                <button onClick={() => setIsOpen(false)} className={styles.modal_close}>
                    <img src={iconClose} alt="close" />
                </button>
            </div>
            <div className={styles.modal_body}>
                <Slider {...settings} className={styles.slider_container}>
                    {
                        currentData?.attachments?.map((item, index) => {
                            return <div className={`${styles.modal_image} ${index === currentSlide && currentData?.attachments?.length > 3 ? styles.modal_image_current : ''}`} key={index}>
                                <img src={item?.url} alt="image" />
                            </div>
                        })
                    }
                </Slider>
                <h2 className={styles.slider_title}>{currentData?.title}</h2>


                <div className={styles.modal_text} dangerouslySetInnerHTML={{ __html: currentData?.description }}></div>

            </div>


        </Modal>

    </div>
});