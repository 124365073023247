import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from './selectBread.module.css';
import { Link, useLocation, useNavigate } from "react-router-dom";
import ImageButtonGreenLeft from '../tractor_image/button/ButtonGreenLeft.png'
import ImageButtonGreenRight from '../tractor_image/button/ButtonGreenRight.png'
import { ButtonSelect } from '../component/button_select'
import { useState } from "react";
import { useContext } from "react";
import { Store } from "../store/store";


const PrevArrow = ({ className, style, onClick }) => {
  return <img src={ImageButtonGreenLeft} alt="" className={styles.prevArrow} onClick={onClick} />

}
const NextArrow = ({ className, style, onClick }) => {
  return <img src={ImageButtonGreenRight} alt="" className={styles.nextArrow} onClick={onClick} />
}

export const SelectBread = ({ linkTo, listMachine }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate();

  const store = useContext(Store);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex)
    }
  };

  const handlerSelect = () => {
    store.setTractor(listMachine?.[currentSlide]?.id)
    navigate(linkTo)
  }

  return (
    <div className={styles.container}>
      <div className={styles.container_border_slider}>
        <div className={styles.container_slider}>
          <div className={styles.title}>{listMachine[currentSlide]?.name}</div>
          <Slider {...settings}>
            {
              listMachine?.map((item, index) => {
                return <div className={styles.cell} key={index}>{item?.description}</div>
              })
            }
          </Slider>
          <ButtonSelect className={styles.btn_select} onClick={handlerSelect} />
        </div>
      </div>

    </div>
  );
};
