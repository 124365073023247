import Slider from "react-slick";
import styles from './selectBread.module.css';
import { SelectBread } from '../../component/selectBread';
import imageRedTractor from '../../tractor_image/tractors/red_tractor.png'
import imageGreenTractor from '../../tractor_image/tractors/green_tractor.png'
import imageBlueTractor from '../../tractor_image/tractors/blue_tractor.png'
import imageOrangeTractor from '../../tractor_image/tractors/orange_tractor.png'
import { ButtonInfo } from '../../component/button_info';

export const PageSelectMachine = () => {

    return (
        <div className={styles.container}>
            <ButtonInfo to={'/'} />
            <SelectBread linkTo={'/level-5/bakery'} listMachine={[
                {
                    id: 'borodino_bread',
                    name: "Ржаной хлеб",
                    description: `Сначала готовится опара, для этого смешивается мука, вода и закваска в следующей пропорции:
                    Мука – 0,5 кг.
                    Вода – 0,9 л.
                    Закваска – 0,3 кг
                    Опара настаивается в течении 4-х часов, после этого на основе опары заводится тесто. Для этого в опару добавляют 0,75 кг муки, 1 столовую ложку соли. Далее тесто настаивается в течении 1,5 часа.`,
                },
                {
                    id: 'borodino_bread',
                    name: "Бородинский хлеб",
                    description: `Бородинский хлеб приготовляется с применением заварки.
                    Заварка. В ящик насыпается 1,5 кг ржаной муки, 0,07 кг солода, приливается 0,15 л. воды температурой 60 градусов и все хорошо перемешивается. Далее, при беспрерывном помешивании прибавляется крутой кипяток в количестве 0,4 л.`,
                },
                {
                    id: 'borodino_bread',
                    name: "Пшеничный хлеб",
                    description: `Тесто приготовляется опарным способом. Для приготовления опары используется:
                    Мука – 0,7 кг.
                    Дрожжи – 0,01 кг
                    Вода – 0,5 л.
                    Опара настаивается 2,5 – 3 часа, после этого приступают к созданию теста. Для этого в опару добавляется 0,7 кг муки, около 0,3 л воды, 0,02 кг (столовая ложка без горки) соли.`,
                },
            ]} />
        </div>
    );
};
