import { useState } from 'react'
import styles from './farm_field.module.css'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { Store } from '../../store/store';
import { useContext } from "react";
import plowed from '../../tractor_image/fields/plowed.png'
import plow from '../../tractor_image/fields/plow.png'
import seeds from '../../tractor_image/fields/seeds.png'
import seed from '../../tractor_image/fields/seed.png'
import { ClickerField } from '../../component/clicker_field'
import { Hero } from '../../component/hero'
import { MenuFarm } from '../../component/menu_farm';
import { ButtonInfo } from '../../component/button_info';


export const PageFarmField = observer(() => {
    const store = useContext(Store);
    const [finishSowing, setFinishSowing] = useState(false);
    const navigate = useNavigate();

    return (
        <div className={styles.container}>
            <ButtonInfo to={'/'} />
            <MenuFarm first={true} second={true} />
            <ClickerField image_before={plow} image_after={seed} tractorId={store.tractor} callBackFullFields={() => { console.log("123"); setFinishSowing(true) }} />
            <Hero nextMessage={() => { console.log("next") }} showDialog={finishSowing} finishMessages={() => { navigate('/level-3/farm_field')}} messages={["Отличная работа! А теперь нам нужно полить наши посевы..."]} hasHero={true} nameHero={'Виктор'} />
        </div>
    )
})
