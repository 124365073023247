import Slider from "react-slick";
import styles from './selectMachine.module.css';
import { ButtonInfo } from '../../component/button_info';
import { SelectMachine } from '../../component/selectMachine';
import imageRedTractor from '../../tractor_image/tractors/red_tractor.png'
import imageGreenTractor from '../../tractor_image/tractors/green_tractor.png'
import imageBlueTractor from '../../tractor_image/tractors/blue_tractor.png'
import imageOrangeTractor from '../../tractor_image/tractors/orange_tractor.png'

export const PageSelectMachine = () => {

    return (
        <div className={styles.container}>
            <ButtonInfo to={'/'} />
            <SelectMachine linkTo={'/level-1/farm_field'} listMachine={[
                {
                    id: 'red_tractor',
                    image: <img src={imageRedTractor} alt={''} />,
                    name: "Медвежонок (МДВ-18)",
                    description: "Медвежонок (МДВ-18)",
                },
                {
                    id: 'green_tractor',
                    image: <img src={imageGreenTractor} alt={''} />,
                    name: "Зернопоглощатель (ЗПГ-550)",
                    description: "Зернопоглощатель (ЗПГ-550)",
                },
                {
                    id: 'blue_tractor',
                    image: <img src={imageBlueTractor} alt={''} />,
                    name: "Буханочка-ракета (БХР-450)",
                    description: "Буханочка-ракета (БХР-450)",
                },
                {
                    id: 'orange_tractor',
                    image: <img src={imageOrangeTractor} alt={''} />,
                    name: "Сельский Злодей (СЛД-500)",
                    description: "Сельский Злодей (СЛД-500)"
                },
            ]} />
        </div>
    );
};
