import { useState } from 'react'
import styles from './bakery.module.css'
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { Store } from '../../store/store';
import { useContext } from "react";
import imgStove from '../../tractor_image/stove/stove.png'
import imgStove1 from '../../tractor_image/stove/stove_form1.png'
import imgStoveMid1 from '../../tractor_image/stove/stove_form1_mid.png'
import imgStoveEnd1 from '../../tractor_image/stove/stove_form1_end.png'
import imgStove2 from '../../tractor_image/stove/stove_form2.png'
import imgStoveMid2 from '../../tractor_image/stove/stove_form2_mid.png'
import imgStoveEnd2 from '../../tractor_image/stove/stove_form2_end.png'
import imgStove3 from '../../tractor_image/stove/stove_form3.png'
import imgStoveMid3 from '../../tractor_image/stove/stove_form3_mid.png'
import imgStoveEnd3 from '../../tractor_image/stove/stove_form3_end.png'
import imgTableEmpty from '../../tractor_image/table/table_empty.png'
import { Hero } from '../../component/hero';
import { Timer } from '../../component/timer';
import { ButtonInfo } from '../../component/button_info';


export const PageFarmField = observer(() => {
    const store = useContext(Store);
    const navigate = useNavigate();
    const [finishSowing, setFinishSowing] = useState(false);
    const [restartSowing, setRestartSowing] = useState(false);
    const [timer, setTimer] = useState(0);
    const [timerStop, setTimerStop] = useState(false);

    const getBread = () => {
        if(timer < 7) return
        if(timer > 10) {
            setRestartSowing(true)
            return
        }
        store.setSecond(timer);
        setTimerStop(true);
        setFinishSowing(true);
    }

    const renderStove = () => {
        let img = null;
        if (store.bread_form === 1) {
            img = imgStove1
            if (timer >= 7) {
                img = imgStoveMid1;
            }
            if (timer > 10) {
                img = imgStoveEnd1;
            }
        } else if (store.bread_form === 2) {
            img = imgStove2
            if (timer >= 7) {
                img = imgStoveMid2;
            }
            if (timer > 10) {
                img = imgStoveEnd2;
            }
        } else if (store.bread_form === 3) {
            img = imgStove3
            if (timer >= 7) {
                img = imgStoveMid3;
            }
            if (timer > 10) {
                img = imgStoveEnd3;
            }
        }

        return <img src={img} alt="" className={styles.stove} onClick={getBread} />

    }



    return (
        <div className={styles.container}>
            <ButtonInfo to={'/'} />
            {renderStove()}
            <div className={styles.dough}>
                <img src={imgTableEmpty} alt="" />
            </div>
            <Timer setTimer={setTimer} stop={timerStop} />
            <Hero nextMessage={() => { console.log("next") }} showDialog={restartSowing} finishMessages={() => { navigate('/level-5/select-bread') }} messages={["Попробуй еще раз!"]} nameHero={'Зоя'} />
            <Hero nextMessage={() => { console.log("next") }} showDialog={finishSowing} finishMessages={() => { navigate('/level-10/bakery') }} messages={["Молодец, у тебя получилось испечь хлеб"]} nameHero={'Зоя'} />
        </div>
    )
})
