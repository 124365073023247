import ImageOrganization from '../tractor_image/test_image/card_organization_image.png';
import styles from './card_organization.module.css'

export const CardOrganization = ({ buttonClick, item }) => {
    return <div className={styles.container}>
        <div>
            <img src={item?.image} alt="" className={styles.image} />
        </div>
        <div className={styles.container_content}>
            <div className={styles.title}>{item?.title}</div>
            <button className={styles.btn} onClick={buttonClick}>Подробнее</button>
        </div>
    </div>
}