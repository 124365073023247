import { useState } from 'react'
import styles from './farm_field.module.css'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { Store } from '../../store/store';
import { useContext } from "react";
import wheat from '../../tractor_image/fields/wheat.png'
import plowed from '../../tractor_image/fields/plowed.png'
import { FarmField } from '../../component/farm_field'
import { Hero } from '../../component/hero'
import { MenuFarm } from '../../component/menu_farm';
import { ButtonInfo } from '../../component/button_info';


export const PageFarmField = observer(() => {
    const store = useContext(Store);
    const navigate = useNavigate();
    const [finishSowing, setFinishSowing] = useState(false);

    return (
        <div className={styles.container}>
            <ButtonInfo to={'/'} />
            <MenuFarm first={true} second={true} third={true} fourth={true} />
            <FarmField image_before={wheat} image_after={plowed} tractorId={store.tractor} callBackFullFields={() => { setFinishSowing(true) }} fixStyle={true} />
            <Hero nextMessage={() => { console.log("next") }} showDialog={finishSowing} finishMessages={() => { navigate('/level-5/select-bread') }} messages={["Урожай собран, теперь мы сможем его отправить на производство и испечь самый вкусный хлеб, поторопись тебя уже ждут"]} hasHero={true} nameHero={'Виктор'} />
        </div>
    )
})
