import { useState } from 'react'
import styles from './bakery.module.css'
import { observer } from "mobx-react";
import { Store } from '../../store/store';
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import iconFlour from '../../tractor_image/ingredients/flour.png'
import iconLeaven from '../../tractor_image/ingredients/Leaven.png'
import iconYeast from '../../tractor_image/ingredients/Yeast.png'
import iconSalt from '../../tractor_image/ingredients/Salt.png'
import iconBowl from '../../tractor_image/ingredients/The_bowl_empty.png'
import { useDroppable, useDraggable } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import { ButtonInfo } from '../../component/button_info';

export const Droppable = ({ children, className }) => {
    const { isOver, setNodeRef } = useDroppable({
        id: 'droppable',
    });
    const style = {
        color: isOver ? 'green' : undefined,
    };

    return (
        <div ref={setNodeRef} style={style} className={className}>
            {children}
        </div>
    );
}

export const Draggable = ({ children, className, name, disabled }) => {
    const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id: name || 'awesome',
        disabled: disabled
    });
    const style = transform ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    } : undefined;


    return (
        <button ref={setNodeRef} style={style} className={`${className} ${disabled ? styles.disabled : ''}`} {...listeners} {...attributes}>
            {children}
        </button>
    );
}

export const PageFarmField = observer(() => {
    const navigate = useNavigate();
    const store = useContext(Store);
    const [finishSowing, setFinishSowing] = useState(false);
    const [productsInBowl, setProductsInBowl] = useState([]);

    const hasFullProductsInBowl = () => {
        return (
            productsInBowl.indexOf("Flour") >= 0 &&
            productsInBowl.indexOf("Leaven") >= 0 &&
            productsInBowl.indexOf("Yeast") >= 0 &&
            productsInBowl.indexOf("Salt") >= 0
        )
    }

    const draggableMarkup = (e) => {
        const name = e.active.id;
        const droppable = e.over?.id;
        if (droppable === 'droppable') {
            setProductsInBowl([...productsInBowl, name])
        }
    }

    const hasProductsInBowl = (name) => {
        return productsInBowl.indexOf(name) >= 0;
    }

    if (hasFullProductsInBowl()) {
        navigate("/level-6/bakery");
    }

    return (
        <DndContext onDragEnd={draggableMarkup}>
            <ButtonInfo to={'/'} />
            <div className={styles.container}>
                <Droppable className={styles.bowl}>
                    <img src={iconBowl} alt="" />
                </Droppable>
                <div className={styles.leftMenu}>
                    <div className={styles.menuCell} >
                        <Draggable className={styles.containerImage} name="Flour" disabled={hasProductsInBowl("Flour")}>
                            <img src={iconFlour} alt="" className={styles.cellImage} />
                        </Draggable>
                        <div className={styles.cellName}>Мука 1-го сорта</div>
                    </div>
                    <div className={styles.menuCell}>
                        <Draggable className={styles.containerImage} name="Leaven" disabled={hasProductsInBowl("Leaven")}>
                            <img src={iconLeaven} alt="" className={styles.cellImage} />
                        </Draggable>
                        <div className={styles.cellName}>Заквазка</div>
                    </div>
                    <div className={styles.menuCell}>
                        <Draggable className={styles.containerImage} name="Yeast" disabled={hasProductsInBowl("Yeast")}>
                            <img src={iconYeast} alt="" className={styles.cellImage} />
                        </Draggable>
                        <div className={styles.cellName}>Дрожжи</div>
                    </div>
                    <div className={styles.menuCell}>
                        <Draggable className={styles.containerImage} name="Salt" disabled={hasProductsInBowl("Salt")}>
                            <img src={iconSalt} alt="" className={styles.cellImage} />
                        </Draggable>
                        <div className={styles.cellName}>Соль</div>
                    </div>
                </div>
            </div>
        </DndContext>
    )
})
