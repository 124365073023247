import { useEffect, useState } from 'react'
import styles from './bakery.module.css'
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { Store } from '../../store/store';
import { useContext } from "react";
import imgTableEmpty from '../../tractor_image/table/table_empty.png'
import imgTableForm1 from '../../tractor_image/table/table_form1.png'
import imgTableForm2 from '../../tractor_image/table/table_form2.png'
import imgTableForm3 from '../../tractor_image/table/table_form3.png'
import { Hero } from '../../component/hero';
import { Timer } from '../../component/timer';
import { ButtonInfo } from '../../component/button_info';


export const PageFarmField = observer(() => {
    const store = useContext(Store);
    const navigate = useNavigate();
    const [finishSowing, setFinishSowing] = useState(false);

    useEffect(() => {
        setFinishSowing(true)
    }, [])

    const getBread = () => {
        if (store.bread_form === 1) {
            return <img src={imgTableForm1} alt="" />
        } else if (store.bread_form === 2) {
            return <img src={imgTableForm2} alt="" />
        } else if (store.bread_form === 3) {
            return <img src={imgTableForm3} alt="" />
        }
        return <img src={imgTableEmpty} alt="" />
    }

    return (
        <div className={styles.container}>
            <ButtonInfo to={'/'} />
            <div className={styles.dough}>
                {getBread()}
            </div>
            <Hero nextMessage={() => { console.log("next") }} showDialog={finishSowing} finishMessages={() => { navigate('/level-11/bakery') }} messages={["Благодарю тебя за помощь"]} nameHero={'Зоя'} />
        </div>
    )
})
