import { useState } from 'react'
import styles from './bakery.module.css'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { Store } from '../../store/store';
import { useContext } from "react";
import iconDough1 from '../../tractor_image/dough/dough_1.png'
import iconDough2 from '../../tractor_image/dough/dough_2.png'
import iconDough3 from '../../tractor_image/dough/dough_3.png'
import iconDough4 from '../../tractor_image/dough/dough_4.png'
import { MenuBakery } from '../../component/menu_bakery';
import { Hero } from '../../component/hero';
import { ButtonInfo } from '../../component/button_info';

export const PageFarmField = observer(() => {
    const store = useContext(Store);
    const navigate = useNavigate();
    const [finishSowing, setFinishSowing] = useState(false);
    const [countClick, setCountClick] = useState(0);


    const renderDough = () => {
        if (countClick < 5) {
            return <img src={iconDough1} alt="" />
        } else if (countClick < 10) {
            return <img src={iconDough2} alt="" />
        } else if (countClick < 15) {
            return <img src={iconDough3} alt="" />
        } else if (countClick < 20) {
            return <img src={iconDough4} alt="" />
        } else {
            if (!finishSowing) {
                setFinishSowing(true)
            }

            return <img src={iconDough4} alt="" />
        }
    }

    return (
        <div className={styles.container}>
            <ButtonInfo to={'/'} />
            {/* <MenuBakery handleClick={handlerClickMenu} /> */}
            <div className={styles.dough} onClick={(e) => setCountClick((old) => old + 1)}>
                {renderDough()}
            </div>
            <Hero nextMessage={() => { console.log("next") }} showDialog={finishSowing} finishMessages={() => { navigate('/level-8/bakery') }} messages={["Выбери форму для нашего хлеба и положи его в печь"]} nameHero={'Зоя'} />
        </div>
    )
})
