import { useState } from 'react'
import styles from './bakery.module.css'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { Store } from '../../store/store';
import { useContext } from "react";
import iconBowlDough from '../../tractor_image/ingredients/bowl_of_dough.png'
import { MenuBakery } from '../../component/menu_bakery';
import { Hero } from '../../component/hero';
import { ButtonInfo } from '../../component/button_info';

export const PageFarmField = observer(() => {
    const store = useContext(Store);
    const navigate = useNavigate();
    const [finishSowing, setFinishSowing] = useState(true);
    const [bowlForm, setBowlForm] = useState(0);


    return (
        <div className={styles.container}>
            <ButtonInfo to={'/'} />
            <div className={styles.bowl}>
                <img src={iconBowlDough} alt="" />
            </div>
            <Hero nextMessage={() => { console.log("next") }} showDialog={finishSowing} finishMessages={() => { navigate('/level-7/bakery') }} messages={["Спасибо тебе за помощь! Хорошо мы тесто замешали, пора его помять", "Не сбавляй темпа нажимай на тесто!"]} nameHero={'Зоя'} />
        </div>
    )
})
