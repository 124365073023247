import ImagePersone from '../tractor_image/test_image/card_persone_image.png';
import styles from './card_persone.module.css'

export const CardPersone = ({ item, buttonClick }) => {
    return <div className={styles.container}>
        <div>
            <img src={item?.image} alt="" className={styles.image} />
        </div>
        <div className={styles.container_content}>
            <div className={styles.title}>{item?.title}</div>
            <pre className={styles.description} dangerouslySetInnerHTML={{ __html: item?.description }}></pre>
            <button className={styles.btn} onClick={buttonClick} >Просмотреть грамоты</button>
        </div>
    </div>
}