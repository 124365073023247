import { useState } from 'react'
import styles from './bakery.module.css'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { Store } from '../../store/store';
import { useContext } from "react";
import iconFormBread1 from '../../tractor_image/form_bread/form_bread_1.png'
import iconFormBread2 from '../../tractor_image/form_bread/form_bread_2.png'
import iconFormBread3 from '../../tractor_image/form_bread/form_bread_3.png'
import imgStove from '../../tractor_image/stove/stove.png'
import iconDough4 from '../../tractor_image/dough/dough_4.png'
import { MenuBakery } from '../../component/menu_bakery';
import { Hero } from '../../component/hero';
import { ButtonInfo } from '../../component/button_info';


export const PageFarmField = observer(() => {
    const store = useContext(Store);
    const navigate = useNavigate();
    const [finishSowing, setFinishSowing] = useState(false);

    const [selectForm, setSelectForm] = useState(-1);

    const handlerClickMenu = (id) => {
        store.setBreadForm(id);
        setSelectForm(id)
    }

    const renderDough = () => {
        if (selectForm === 1) {
            return <img src={iconFormBread1} alt="" />
        } else if (selectForm === 2) {
            return <img src={iconFormBread2} alt="" />
        } else if (selectForm === 3) {
            return <img src={iconFormBread3} alt="" />
        }
        return <img src={iconDough4} alt="" />
    }

    return (
        <div className={styles.container}>
            <ButtonInfo to={'/'} />
            <MenuBakery handleClick={handlerClickMenu} />
            <img src={imgStove} alt="" className={styles.stove} onClick={() => {
                if (selectForm > 0) {
                    setFinishSowing(true)
                }
            }} />
            <div className={styles.dough}>
                {renderDough()}
            </div>
            <Hero nextMessage={() => { console.log("next") }} showDialog={finishSowing} finishMessages={() => { navigate('/level-9/bakery') }} messages={["Отличная форма, пора ставить его выпекаться, но не забудь следить за временем...", "Пеки больше 7, но не более 10 секунд"]} nameHero={'Зоя'} />
        </div>
    )
})
