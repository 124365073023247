import {
    createBrowserRouter,
    RouterProvider,
    BrowserRouter,
    Route,
    Routes,
    useLocation,
} from "react-router-dom";
import backgroundMusic from '../audio/Roa - Daybreak.mp3';
import { Main } from '../screen/main';
import { PreStartGame } from '../screen/pre_start_game';
import { StartGame } from '../screen/start_game';
import { About } from '../screen/about/about';
import { Frontrunners } from '../screen/about/frontrunners';
import { Organization } from '../screen/about/organization';
import { Works } from '../screen/about/works';

import { PageSelectMachine } from '../screen/level-1/selectMachine';
import { PageFarmField } from '../screen/level-1/farm_field';

import { PageSelectMachine as PageSelectMachine2 } from '../screen/level-2/selectMachine';
import { PageFarmField as PageFarmField2 } from '../screen/level-2/farm_field';

import { PageSelectMachine as PageSelectMachine3 } from '../screen/level-3/selectMachine';
import { PageFarmField as PageFarmField3 } from '../screen/level-3/farm_field';

import { PageSelectMachine as PageSelectMachine4 } from '../screen/level-4/selectMachine';
import { PageFarmField as PageFarmField4 } from '../screen/level-4/farm_field';

import { PageSelectMachine as PageSelectBread5 } from '../screen/level-5/selectBread';
import { PageFarmField as PageBakery5 } from '../screen/level-5/bakery';

import { PageFarmField as PageBakery6 } from '../screen/level-6/bakery';

import { PageFarmField as PageBakery7 } from '../screen/level-7/bakery';

import { PageFarmField as PageBakery8 } from '../screen/level-8/bakery';

import { PageFarmField as PageBakery9 } from '../screen/level-9/bakery';

import { PageFarmField as PageBakery10 } from '../screen/level-10/bakery';

import { PageFarmField as PageBakery11 } from '../screen/level-11/bakery';
import { useEffect, useRef, useState } from "react";

function AudioPlayer() {
    const location = useLocation();
    const currentPage = location.pathname;
    const audioRef = useRef()
    const hasGameOrLevel = currentPage.indexOf("game") >= 0 || currentPage.indexOf("level") >= 0
    useEffect(() => {
        console.log({ s: audioRef.current, w: audioRef.current?.played, p: audioRef.current?.paused })
        if (hasGameOrLevel) {
            audioRef.current?.play && audioRef.current?.play()
        } else {

            audioRef.current?.pause && audioRef.current?.pause()
            if (audioRef.current.currentTime) {
                audioRef.current.currentTime = 0;
            }
        }

    }, [audioRef.current, hasGameOrLevel])
    return (
        <div style={{ display: "none" }}>
            <p>Текущая страница: {currentPage}</p>
            <audio loop ref={audioRef}>
                <source src={backgroundMusic} type="audio/mpeg" />
                Ваш браузер не поддерживает элемент audio.
            </audio>
        </div>
    );
}


export const Router = () => {
    return <BrowserRouter>
        <AudioPlayer />
        <Routes>

            <Route path="/" element={<Main />} />
            <Route path="/game/pre-start" element={<PreStartGame />} />
            <Route path="/game/start" element={<StartGame />} />
            <Route path="/about" element={<About />} />
            <Route path="/frontrunners" element={<Frontrunners />} />
            <Route path="/organization" element={<Organization />} />
            <Route path="/works" element={<Works />} />
            <Route path="/level-1/select-machine" element={<PageSelectMachine />} />
            <Route path="/level-1/farm_field" element={<PageFarmField />} />
            <Route path="/level-2/select-machine" element={<PageSelectMachine2 />} />
            <Route path="/level-2/farm_field" element={<PageFarmField2 />} />
            <Route path="/level-3/select-machine" element={<PageSelectMachine3 />} />
            <Route path="/level-3/farm_field" element={<PageFarmField3 />} />
            <Route path="/level-4/select-machine" element={<PageSelectMachine4 />} />
            <Route path="/level-4/farm_field" element={<PageFarmField4 />} />
            <Route path="/level-5/select-bread" element={<PageSelectBread5 />} />
            <Route path="/level-5/bakery" element={<PageBakery5 />} />
            <Route path="/level-6/bakery" element={<PageBakery6 />} />
            <Route path="/level-7/bakery" element={<PageBakery7 />} />
            <Route path="/level-8/bakery" element={<PageBakery8 />} />
            <Route path="/level-9/bakery" element={<PageBakery9 />} />
            <Route path="/level-10/bakery" element={<PageBakery10 />} />
            <Route path="/level-11/bakery" element={<PageBakery11 />} />
        </Routes>
    </BrowserRouter>
}