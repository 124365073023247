import Slider from "react-slick";
import { observer } from "mobx-react";
import { Store } from '../../store/store';
import { useContext, useEffect, useState } from "react";
import { ButtonLinkArray } from '../../component/button_link_array';
import { ButtonLink } from '../../component/button_link';
import styles from './frontrunners.module.css';
import iconClose from '../../tractor_image/icon/close.png';
import IconSearch from '../../tractor_image/icon/search.png';
import { CardPersone } from '../../component/card_persone';
import ImageButtonRedRight from '../../tractor_image/arrow/arrow_red_right.png'
import Modal from 'react-modal';

const PrevArrow = ({ className, style, onClick }) => {
    return <img src={ImageButtonRedRight} alt="" className={styles.prevArrow} onClick={onClick} />

}
const NextArrow = ({ className, style, onClick }) => {
    return <img src={ImageButtonRedRight} alt="" className={styles.nextArrow} onClick={onClick} />
}

export const Frontrunners = observer(() => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const store = useContext(Store);

    const settings = {
        dots: false,
        infinite: true,
        adaptiveHeight: true,
        slidesToScroll: 1,
        centerMode: true,
        // centerPadding: "100px",
        slidesToShow: 3,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        beforeChange: (oldIndex, newIndex) => {
            setCurrentSlide(newIndex)
        }
    };

    const [data, setData] = useState({});
    const [search, setSearch] = useState("");
    useEffect(() => {
        (async () => {
            const res = await fetch("https://museum.dvizh.net/api/frontrunners?search=" + search)
            const res_data = await res.json()
            console.log(res_data);
            setData(res_data);
        })()
    }, [search])

    const [modalIsOpen, setIsOpen] = useState(false)
    const [currentData, setCurrentData] = useState({})

    return <div className={styles.container}>
        <div className={styles.container_content}>

            <h1 className={styles.pageTitle}>
                Передовики
            </h1>
            <p className={styles.pageText}>
                А ещё реплицированные с зарубежных источников, современные исследования, вне зависимости от их уровня, должны быть указаны как претенденты на
                роль ключевых факторов. Банальные, но неопровержимые выводы, а также диаграммы связей формируют глобальную экономическую сеть и при этом —
                своевременно верифицированы. Каждый из нас понимает очевидную вещь: понимание сути ресурсосберегающих технологий предопределяет
                высокую востребованность форм воздействия.
            </p>
            <div className={styles.bottom_links}>
                <ButtonLink to={'/about'}>
                    Назад
                </ButtonLink>
            </div>
        </div>

        <div className={styles.container_list}>
            <div className={styles.search_container}>
                <input type="text" className={styles.search_input} placeholder="Найти передовика" value={search} onInput={(e) => setSearch(e.target.value)} />
                <img src={IconSearch} alt='' className={styles.search_icon} />
            </div>
            <div className={styles.list}>
                {data?.items?.map((item, index) => {
                    return <CardPersone item={item} key={index} buttonClick={() => {setIsOpen(true); setCurrentData({...item}); setCurrentSlide(0);}} />
                })}
            </div>
        </div>


        <Modal
            isOpen={modalIsOpen}
            // onAfterOpen={afterOpenModal}
            onRequestClose={() => setIsOpen(false)}
            className={styles.modal}
            contentLabel="Example Modal"
            overlayClassName={styles.modal_bg}
            ariaHideApp={false}
        >
            <div className={styles.modal_head}>
                <button onClick={() => setIsOpen(false)} className={styles.modal_close}>
                    <img src={iconClose} alt="close" />
                </button>
            </div>
            <div className={styles.modal_body}>
                <Slider {...settings} className={styles.slider_container}>
                    {
                        currentData?.attachments?.map((item, index) => {
                            return <div className={`${styles.modal_image} ${index === currentSlide && currentData?.attachments?.length > 3 ? styles.modal_image_current : ''}`} key={index}>
                                <img src={item?.url} alt="image" />
                            </div>
                        })
                    }
                </Slider>
                <h2 className={styles.slider_title}>{currentData?.title}</h2>


                <div className={styles.modal_text} dangerouslySetInnerHTML={{ __html: currentData?.description }}></div>

            </div>


        </Modal>

    </div>
});