import { makeAutoObservable } from 'mobx';
import { createContext } from 'react';

class AppStore {

    constructor() {
        makeAutoObservable(this);
    }

    data = null;

    tractor = null;

    bread_form = null;

    second = 0;


    setData = (data) => {
        console.log('setData', 'ok')
        this.data = data;
    };

    setTractor = (name) => {
        console.log('setTractor', name)
        this.tractor = name;
    };

    setBreadForm = (Form) => {
        console.log('setBreadForm', Form)
        this.bread_form = Form;
    };

    setSecond = (sec) => {
        console.log('setBreadForm', sec)
        this.second = sec;
    };
}

export const Store = createContext(new AppStore());
