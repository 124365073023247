import { useState, useRef } from 'react'
import styles from './farm_field.module.css'
import imageRedTractor from '../tractor_image/tractors/red_tractor_move.gif'
import imageBlueTractor from '../tractor_image/tractors/blue_tractor_move.gif'
import imageGreenTractor from '../tractor_image/tractors/green_tractor_move.gif'
import imageOrangeTractor from '../tractor_image/tractors/orange_tractor_move.gif'
import imageHarvester from '../tractor_image/tractors/harvester.gif'

const Tractor = ({ tractorId }) => {

    if (tractorId === 'red_tractor') {
        return <img src={imageRedTractor} className={styles.tractor} alt="" draggable="false" />
    }
    if (tractorId === 'blue_tractor') {
        return <img src={imageBlueTractor} className={styles.tractor} alt="" draggable="false" />
    }
    if (tractorId === 'green_tractor') {
        return <img src={imageGreenTractor} className={styles.tractor} alt="" draggable="false" />
    }
    if (tractorId === 'orange_tractor') {
        return <img src={imageOrangeTractor} className={styles.tractor} alt="" draggable="false" />
    }
    if (tractorId === 'harvester') {
        return <img src={imageHarvester} className={styles.tractor} alt="" draggable="false" />
    }
}

export const FarmField = ({ image_before, image_after, callBackFullFields, tractorId, fixStyle }) => {
    const [fields, setFields] = useState([{}, {}, {}]);
    const timeout = useRef(null);
    const handlerClickTractor = (id) => {
        if (timeout.current != null) return;
        fields[id].processed = true;
        setFields([...fields]);
        timeout.current = setTimeout(() => {
            clearTimeout(timeout.current);
            timeout.current = null;
            const hasAllProcessed = fields.every(obj => obj.hasOwnProperty('processed'));
            hasAllProcessed && callBackFullFields()
        }, 6000);
    }


    return (
        <div className={styles.container_field}>
            {fields.map((value, id) => {
                return (
                    <div className={`${styles.field} ${value?.processed && styles.sowing}`}
                        key={id}
                        onClick={() => {
                            handlerClickTractor(id);
                        }}
                    >
                        <div className={styles.field_contain_before} style={fixStyle ? {bottom: '4px'} : {}}>
                            <img src={image_before} alt="" className={styles.field_before} draggable="false" />
                        </div>

                        <div className={styles.field_contain_after}>
                            <img src={image_after} alt="" className={styles.field_after} draggable="false" />
                        </div>
                        {value?.processed && <Tractor tractorId={tractorId} />}
                    </div>
                )
            })}
        </div>
    )
}
