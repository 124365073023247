import { ButtonLink } from "./button_link";
import styles from './button_link_array.module.css'
import arrow from '../tractor_image/arrow/arrow.png'


export const ButtonLinkArray = ({ to, children }) => {
    return <ButtonLink className={`${styles.button}`} to={to}>
        {children}
        <img src={arrow} alt="" className={styles.arrow} />
    </ButtonLink>
}