import Slider from "react-slick";
import { observer } from "mobx-react";
import { Store } from '../../store/store';
import { useContext, useState, useEffect } from "react";
import { ButtonLinkArray } from '../../component/button_link_array';
import { ButtonLink } from '../../component/button_link';
import styles from './works.module.css';
import IconSearch from '../../tractor_image/icon/search.png';
import { CardOrganization } from '../../component/card_organization';
import ImageButtonRedRight from '../../tractor_image/arrow/arrow_red_right.png'
import ImageSlider from '../../tractor_image/test_image/card_organization_image.png'
import { CardWork } from "../../component/card_work";

const PrevArrow = ({ className, style, onClick }) => {
    return <img src={ImageButtonRedRight} alt="" className={styles.prevArrow} onClick={onClick} />

}
const NextArrow = ({ className, style, onClick }) => {
    return <img src={ImageButtonRedRight} alt="" className={styles.nextArrow} onClick={onClick} />
}

const arrayImage = [
    {
        image: <img src={ImageSlider} alt="image" />,
        title: "Медиа группа “ЧАС ПИК” 1",
        description: "Описание 1",
    },
    {
        image: <img src={ImageSlider} alt="image" />,
        title: "Медиа группа “ЧАС ПИК” 2",
        description: "Описание 2",
    },
    {
        image: <img src={ImageSlider} alt="image" />,
        title: "Медиа группа “ЧАС ПИК” 3",
        description: "Описание 3",
    },
    {
        image: <img src={ImageSlider} alt="image" />,
        title: "Медиа группа “ЧАС ПИК” 4",
        description: "Описание 4",
    },
    {
        image: <img src={ImageSlider} alt="image" />,
        title: "Медиа группа “ЧАС ПИК” 5",
        description: "Описание 5",
    },
    {
        image: <img src={ImageSlider} alt="image" />,
        title: "Медиа группа “ЧАС ПИК” 6",
        description: "Описание 6",
    },
]

export const Works = observer(() => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const store = useContext(Store);

    const settings = {
        dots: false,
        infinite: false,
        slidesToScroll: 3,
        centerMode: false,
        slidesToShow: 3,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        beforeChange: (oldIndex, newIndex) => {
            setCurrentSlide(newIndex)
        }
    };

    const [data, setData] = useState({});
    const [search, setSearch] = useState("");
    useEffect(() => {
        (async () => {
            const res = await fetch("https://museum.dvizh.net/api/agracultural?search=" + search)
            const res_data = await res.json()
            console.log(res_data);
            setData(res_data);
        })()
    }, [search])

    return <div className={styles.container}>
        <div className={styles.container_content}>
            <div className={styles.line_title}>
                <h1 className={styles.title}>
                    сельскохозяйственные <br />
                    предприятия и организации
                </h1>
                <div className={styles.search_container}>
                    <input type="text" className={styles.search_input} placeholder="Найти" value={search} onInput={(e) => setSearch(e.target.value)} />
                    <img src={IconSearch} alt='' className={styles.search_icon} />
                </div>
            </div>

            <p className={styles.pageText}>
                А ещё реплицированные с зарубежных источников, современные исследования, вне зависимости от их уровня, должны быть указаны как претенденты на роль ключевых факторов. Банальные, но неопровержимые выводы, а также диаграммы связей формируют глобальную экономическую сеть и при этом — своевременно верифицированы. Каждый из нас понимает очевидную вещь: понимание сути ресурсосберегающих технологий предопределяет высокую востребованность форм воздействия.
            </p>
            <Slider {...settings} className={styles.slider_container}>
                {
                    data?.items?.map((item, index) => {
                        return <CardWork item={item} key={index} index={index} />
                    })
                }
            </Slider>
            <div className={styles.bottom_links}>
                <ButtonLink to={'/about'}>
                    Назад
                </ButtonLink>
            </div>
        </div>

    </div>
});