import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { Store } from '../store/store';
import { useContext } from "react";
import styles from './start_game.module.css';
import { ButtonInfo } from '../component/button_info';
import ImageHero from '../tractor_image/heroes/hero_man.png';
import { Dialog } from '../component/dialog';
import { ButtonStart } from '../component/button_start';

export const StartGame = observer(() => {
    const store = useContext(Store);



    return <div className={styles.container}>
        <ButtonInfo to={'/'} />
        <Dialog style={{ width: 900, left: 200, bottom: 250 }}>
            Приветствую тебя друг!  Рад видеть тебя и спасибо что вызвался помочь мне.
            <br />
            Сегодня мы с тобой займемся интересным занятием.
            <br />
            Мы вспашем землю, посеем пшеницу, польем ростки и соберем урожай!
            <br />
            Но для начала давай выберем трактор...
            <ButtonStart className={styles.btn_start} to={'/level-1/select-machine'} />
        </Dialog>
        <img src={ImageHero} alt='' className={styles.hero} />
    </div>
});