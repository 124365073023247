import iconForm1 from '../tractor_image/form_bread/form_1.png'
import iconForm2 from '../tractor_image/form_bread/form_2.png'
import iconForm3 from '../tractor_image/form_bread/form_3.png'
import styles from './menu_bakery.module.css'

export const MenuBakery = ({ handleClick }) => {
    return <div className={styles.container}>
        <div className={styles.cell} onClick={() => handleClick(1)}>
            <div className={styles.name}>
                Форма №1
            </div>
            <img src={iconForm1} alt="" className={styles.image} />
        </div>
        <div className={styles.cell} onClick={() => handleClick(2)}>
            <div className={styles.name}>
                Форма №2
            </div>
            <img src={iconForm2} alt="" className={styles.image} />
        </div>
        <div className={styles.cell} onClick={() => handleClick(3)}>
            <div className={styles.name}>
                Форма №3
            </div>
            <img src={iconForm3} alt="" className={styles.image} />
        </div>
    </div>
}