import { observer } from "mobx-react";
import { Store } from '../../store/store';
import { useContext } from "react";
import { ButtonLinkArray } from '../../component/button_link_array';
import { ButtonLink } from '../../component/button_link';
import styles from './about.module.css';

export const About = observer(() => {
    const store = useContext(Store);

    return <div className={styles.container}>

        <h1 className={styles.pageTitle}>
            Послевоенный период в сельском <br />
            хозяйстве Курганской области
        </h1>
        <p className={styles.pageText}>
            Следует отметить, что начало повседневной работы по <br />
            формированию позиции в значительной степени обусловливает <br />
            важность поставленных обществом задач.
        </p>
        <div className={styles.container_link}>
            <ButtonLinkArray to="/frontrunners">Передовики</ButtonLinkArray>
            <ButtonLinkArray to="/organization">
                сельскохозяйственные
                предприятия и организации
            </ButtonLinkArray>
            <ButtonLinkArray to="/works">
                сельскохозяйственные работы
            </ButtonLinkArray>
        </div>
        <div className={styles.bottom_links}>
            <ButtonLink to={'/'}>
                Назад
            </ButtonLink>
        </div>

    </div>
});