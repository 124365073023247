import Slider from "react-slick";
import styles from './selectMachine.module.css';
import { Link } from "react-router-dom";
import { SelectMachine } from '../../component/selectMachine';
import imageRedTractor from '../../tractor_image/tractors/red_tractor.png'
import imageGreenTractor from '../../tractor_image/tractors/green_tractor.png'
import imageBlueTractor from '../../tractor_image/tractors/blue_tractor.png'
import imageHarvester from '../../tractor_image/tractors/harvester.png'
import { ButtonInfo } from '../../component/button_info';


export const PageSelectMachine = () => {

    return (
        <div className={styles.container}>
            <ButtonInfo to={'/'} />
            <SelectMachine linkTo={'/level-4/farm_field'} listMachine={[
                {
                    id: 'harvester',
                    image: <img src={imageHarvester} alt={''} />,
                    name: "\"Комбайн Булка\" (СБ-200)",
                    description: "\"Комбайн Булка\" (СБ-200)"
                },
            ]} />
        </div>
    );
};
