import iconGoldenTractor from '../tractor_image/icon/goldenTractor.png'
import iconGoldenLeaf from '../tractor_image/icon/goldenLeaf.png'
import iconGoldenDrop from '../tractor_image/icon/goldenDrop.png'
import iconGoldenWheat from '../tractor_image/icon/goldenWheat.png'
import styles from './menu_farm.module.css'

export const MenuFarm = ({ first, second, third, fourth }) => {
    return <div className={styles.container}>
        <div className={`${styles.cell} ${first ? '' : styles.disabled}`}>
            <div className={styles.name}>
                Вспахивание
                земли
            </div>
            <img src={iconGoldenTractor} alt="" className={styles.image} />
        </div>
        <div className={`${styles.cell} ${second ? '' : styles.disabled}`}>
            <div className={styles.name}>
                Посев
                пщеницы
            </div>
            <img src={iconGoldenLeaf} alt="" className={styles.image} />
        </div>
        <div className={`${styles.cell} ${third ? '' : styles.disabled}`}>
            <div className={styles.name}>
                Полив
                пщеницы
            </div>
            <img src={iconGoldenDrop} alt="" className={styles.image} />
        </div>
        <div className={`${styles.cell} ${fourth ? '' : styles.disabled}`}>
            <div className={styles.name}>
                Сбор
                урожая
            </div>
            <img src={iconGoldenWheat} alt="" className={styles.image} />
        </div>
    </div>
}