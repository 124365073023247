import { useEffect, useState } from 'react'
import styles from './bakery.module.css'
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { Store } from '../../store/store';
import { useContext } from "react";
import { Hero } from '../../component/hero';
import { Timer } from '../../component/timer';
import { ButtonInfo } from '../../component/button_info';
import imgFinishModal from '../../tractor_image/modal/finish_modal.png'
import imgButtonInfo from '../../tractor_image/button/ButtonInfo.png'
import imgButtonRestart from '../../tractor_image/button/ButtonReastart.png'
import imgFinishCup from '../../tractor_image/modal/finish_cup.png'


export const PageFarmField = observer(() => {
    const store = useContext(Store);
    const navigate = useNavigate();


    return (
        <div className={styles.container}>
            <div className={styles.containerModal}>
                <img src={imgFinishModal} alt="" />
                <img src={imgFinishCup} alt="" className={styles.cup} />
                <div className={styles.buttons}>
                    <img src={imgButtonInfo} className={styles.btnInfo} alt="" onClick={()=>navigate('/')} />
                    <img src={imgButtonRestart} className={styles.btnRestart} alt="" onClick={()=>navigate('/game/start')} />
                </div>
            </div>
        </div>
    )
})
