import ImagePersone from '../tractor_image/test_image/card_persone_image.png';
import styles from './card_work.module.css'
import { Link } from 'react-router-dom';

export const CardWork = ({ index, item }) => {
    return <div className={styles.container}>
        <div className={styles.head}>
            <div className={styles.number}>{index + 1}</div>
            <div className={styles.title}>{item?.title}</div>
        </div>
        <div className={styles.description} dangerouslySetInnerHTML={{ __html: item?.description }}></div>
        <img src={item?.image} alt="" className={styles.image} />

    </div>
}