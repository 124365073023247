import { useState } from 'react'
import styles from './farm_field.module.css'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { Store } from '../../store/store';
import { useContext } from "react";
import unplowed from '../../tractor_image/fields/unplowed.png'
import plowed from '../../tractor_image/fields/plowed.png'
import { FarmField } from '../../component/farm_field'
import { Hero } from '../../component/hero'
import { MenuFarm } from '../../component/menu_farm';
import { ButtonInfo } from '../../component/button_info';

export const PageFarmField = observer(() => {
    const store = useContext(Store);
    const [finishSowing, setFinishSowing] = useState(false);
    const navigate = useNavigate();

    return (
        <div className={styles.container}>
            <ButtonInfo to={'/'} />
            <MenuFarm first={true} />
            <FarmField image_before={unplowed} image_after={plowed} tractorId={store.tractor} callBackFullFields={() => { setFinishSowing(true) }} />
            <Hero nextMessage={() => { console.log("next") }} showDialog={finishSowing} finishMessages={() => { navigate('/level-2/farm_field') }} messages={["Молодец, из тебя вышел хороший тракторист! А теперь займемся посевом..."]} hasHero={true} nameHero={'Виктор'} />
        </div>
    )
})
