import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { Store } from '../store/store';
import { useContext, useEffect, useState } from "react";
import styles from './pre_start_game.module.css';
import { ButtonInfo } from '../component/button_info';
import ImageHero from '../tractor_image/heroes/hero_man.png';
import { Dialog } from '../component/dialog';
import { ButtonStart } from '../component/button_start';
import { useNavigate } from "react-router-dom";

export const PreStartGame = observer(() => {
    const store = useContext(Store);
    const navigate = useNavigate();
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        // setTimeout(() => {
        //     navigate("/game/start")
        // }, 5000)
        const inter = setInterval(() => {
            setProgress((old) => old + 1)
        }, 50)
        return () => {
            clearInterval(inter);
        }
    }, [])
    useEffect(() => {
        if (progress >= 100) {
            navigate("/game/start")
        }
    }, [progress])

    return <div className={styles.container}>
        <div className={styles.loader}>
            <div className={styles.progress}>
                <div className={styles.procent}>{progress}%</div>
            </div>
        </div>
    </div>
});