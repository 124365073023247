import { useEffect, useState, useRef } from "react"
import styles from './timer.module.css'


export const Timer = ({ className, setTimer, stop }) => {
    const [timerValue, setTimerValue] = useState(0);
    const stopRef = useRef(false);


    useEffect(() => {
        const interval = setInterval(() => {
            if (stopRef.current) return
            setTimerValue((old) => old + 1)
            setTimer((old) => old + 1)
        }, 1000)
        return () => {
            clearInterval(interval);
        }
    }, [])

    useEffect(() => {
        stopRef.current = stop;
    }, [stop])

    return <div className={`${styles.container} ${timerValue > 10 ? styles.red : ''} ${className}`}>
        <div className={styles.round}>
            {timerValue}
        </div>
    </div>
}