import { useEffect, useState } from 'react';
import HeroMan from '../tractor_image/heroes/hero_man.png';
import styles from './hero.module.css'
import { Dialog } from './dialog';

export const Hero = ({ messages = [], nextMessage, finishMessages, showDialog, hasHero, nameHero }) => {
    const [messageId, setMessageId] = useState(0);

    const onClick = function (e) {
        if (!showDialog) return;
        const nextMessageId = messageId + 1;
        const hasMessage = messages?.[nextMessageId];
        setMessageId(nextMessageId);
        hasMessage && nextMessage && nextMessage();
        !hasMessage && finishMessages && finishMessages();
    }

    return (
        <div className={styles.container} onClick={onClick}>
            {messages?.[messageId] && showDialog && <Dialog name={nameHero} style={{ width: 500, right: 'calc(100% + 50px)', bottom: 100 }}>{messages?.[messageId]}</Dialog>}
            {hasHero && <img src={HeroMan} alt="" className={styles.hero} />}
        </div>
    )
}
