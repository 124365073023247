import { useState, useRef } from 'react'
import styles from './clicker_field.module.css'
import imageRedTractor from '../tractor_image/tractors/red_tractor_move.gif'
import imageBlueTractor from '../tractor_image/tractors/blue_tractor_move.gif'
import imageGreenTractor from '../tractor_image/tractors/green_tractor_move.gif'
import imageOrangeTractor from '../tractor_image/tractors/orange_tractor_move.gif'

const Tractor = ({ tractorId }) => {

    if (tractorId === 'red_tractor') {
        return <img src={imageRedTractor} className={styles.tractor} alt="" draggable="false" />
    }
    if (tractorId === 'blue_tractor') {
        return <img src={imageBlueTractor} className={styles.tractor} alt="" draggable="false" />
    }
    if (tractorId === 'green_tractor') {
        return <img src={imageGreenTractor} className={styles.tractor} alt="" draggable="false" />
    }
    if (tractorId === 'orange_tractor') {
        return <img src={imageOrangeTractor} className={styles.tractor} alt="" draggable="false" />
    }
}

export const ClickerField = ({ image_before, image_after, callBackFullFields, tractorId }) => {
    const [fields, setFields] = useState([
        {
            cells: [{}, {}, {}]
        },
        {
            cells: [{}, {}, {}]
        },
        {
            cells: [{}, {}, {}]
        },
    ]);
    const timeout = useRef(null);
    const handlerClick = (id, subid) => {
        if (timeout.current != null) return;
        fields[id].cells[subid].processed = true;
        setFields([...fields]);

        const hasAllProcessed = fields.every(obj => obj.cells.every((subobj) => subobj.hasOwnProperty('processed')));
        hasAllProcessed && callBackFullFields()
    }


    return (
        <div className={styles.container_field}>
            {fields.map((value, index) => {
                return (
                    <div className={`${styles.field}`}
                        key={index}
                    >
                        {
                            value.cells.map((subvalue, subindex) => {
                                return <div key={subindex} onClick={() => handlerClick(index, subindex)}>
                                    {
                                        subvalue?.processed ?
                                            <img src={image_after} alt="" className={styles.field_after} draggable="false" /> :
                                            <img src={image_before} alt="" className={styles.field_before} draggable="false" />
                                    }

                                </div>
                            })
                        }

                    </div>
                )
            })}
        </div>
    )
}
