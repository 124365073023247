import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { Store } from '../store/store';
import { useContext, useEffect } from "react";
import { ButtonLink } from '../component/button_link';
import styles from './main.module.css';

export const Main = observer(() => {
    const store = useContext(Store);

    useEffect(() => {
        store.setData(null);
        store.setTractor(null);
        store.setBreadForm(null);
        store.setSecond(0);
    }, [])

    return <div className={styles.container}>
        <p className={styles.top_text}>
            Курганский областной краеведческий музей <br />
            Структурное подразделение ГАУК "Курганское областное музейное объединение"
        </p>
        <h1 className={`${styles.title} ${styles.pageTitle}`}>
            Послевоенный период в сельском <br />
            хозяйстве Курганской области
        </h1>
        <p className={styles.pageText}>
            Следует отметить, что начало повседневной работы по <br />
            формированию позиции в значительной степени обусловливает <br />
            важность поставленных обществом задач.
        </p>
        <div className={styles.container_link}>
            <ButtonLink to="/about">О проекте</ButtonLink>
            <ButtonLink to="/game/pre-start">Начать игру</ButtonLink>
        </div>

    </div>
});